import React, { Component } from "react"
import v5 from "../images/v5.png"
import { CircularProgress } from "@material-ui/core"
import "../css/layout.css"
import "../css/typography.css"

class Redirect extends Component {
  componentDidMount() {
    window.location.replace("https://convaise.com")
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={v5}
          style={{ height: "50px", marginRight: "16px" }}
          alt="Icon Convaise des Logos"
          role="presentation"
        />
        <CircularProgress />
      </div>
    )
  }
}

export default Redirect
